/*
 * Index:
 * - Container Styling
 * - Title Styling
 * - Message Styling
 * - Button Styling
 */

/* Container Styling */
.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
    background-color: #f8f9fa;
    height: 100vh;
    margin: 0;
}

/* Title Styling */
.not-found-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 10px;
}

/* Message Styling */
.not-found-message {
    font-size: 1.2rem;
    color: #6c757d;
    margin-bottom: 20px;
}

/* Button Styling */
.not-found-button {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Button Hover Effect */
.not-found-button:hover {
    background-color: #0056b3;
}
