/*
 * Index:
 * - Step 5 Batteries Page Container
 * - Title
 * - Selection Section
 * - Battery List Section
 * - Battery Options
 * - Battery Option
 * - Calculated Configuration
 * - Media Queries for Responsiveness
 */

/* Step 5 Batteries Page Container */
.step5-batteries-page-container {
    width: 80%;
    margin: 0 auto;
    line-height: 1.2;
}

/* Title */
.step5-config-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #444;
}

/* Selection Section */
.step5-selection-section {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

.step5-input-group {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #f4f4f4;
}

.step5-input-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
}

.step5-input-group select, .step5-input-group input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
}

/* Battery List Section */
.step5-select-battery-section {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #fafafa;
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 20px;
}

/* Battery Options */
.step5-battery-options {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* Battery Option */
.step5-battery-option {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.step5-battery-option:hover {
    background-color: #f0f8ff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.step5-battery-option.selected {
    border-color: #007bff;
    background-color: #e6f2ff;
    font-weight: bold;
}

/* Calculated Configuration */
.step5-calculated-configuration {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #fafafa;
}

.step5-calculated-configuration p {
    margin: 5px 0;
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 768px) {
    .step5-batteries-page-container {
        width: 90%;
    }

    .step5-selection-section {
        flex-direction: column;
        gap: 10px;
    }

    .step5-select-battery-section {
        max-height: 300px;
    }
}
