/*
 * Index:
 * - Wrapper
 * - Container
 * - Form Group
 * - Error Message
 */

/* Wrapper */
.step1-intro-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 57vh;
    padding: 20px;
}

/* Container */
.step1-intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    box-sizing: border-box;
}

/* Form Group */
.step1-form-group {
    margin-bottom: 20px;
    width: 100%;
}

.step1-form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.step1-form-control {
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    font-size: 16px;
    box-sizing: border-box;
}

.step1-form-control:focus {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

/* Error Message */
.step1-error-message {
    color: #dc3545;
    text-align: center;
    margin-top: 10px;
}
