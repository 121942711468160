/*
 * Index:
 * - Navbar Styling
 * - Navbar Links Styling
 * - Language Switcher Styling
 * - Navbar User Container
 * - Navbar User Styling
 * - Navbar Dropdown Styling
 * - Dropdown Items Styling
 */

/* Navbar Styling */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #333;
    color: #fff;
}

/* Navbar Links Styling */
.navbar-links a {
    color: #fff;
    text-decoration: none;
    margin-right: 1rem;
    transition: color 0.2s;
}

.navbar-links a:hover {
    color: #ddd;
}

/* Language Switcher Styling */
.navbar-language-switcher {
    display: flex;
    align-items: center;
    margin-right: 2rem;
}

.navbar-language-switcher button {
    background: none;
    border: none;
    color: #fff;
    margin-left: 5px;
    cursor: pointer;
    font-size: 0.85rem;
    padding: 0;
}

.navbar-language-switcher button.active {
    font-weight: bold;
    text-decoration: underline;
}

.navbar-language-switcher span {
    color: #fff;
    margin: 0 3px;
}

/* Navbar User Container */
.navbar-user-container {
    display: flex;
    align-items: center;
}

/* Navbar User Styling */
.navbar-user {
    position: relative;
    cursor: pointer;
}

.navbar-username {
    transition: color 0.2s;
}

.navbar-username:hover {
    color: #ddd;
}

/* Navbar Dropdown Styling */
.navbar-dropdown {
    display: none;
    position: absolute;
    top: 2.5rem;
    right: 0;
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.navbar-dropdown.open {
    display: block;
}

/* Dropdown Items Styling */
.navbar-dropdown a,
.navbar-dropdown button {
    color: #333;
    text-decoration: none;
    display: block;
    margin-bottom: 0.5rem;
    transition: color 0.2s;
}

.navbar-dropdown a:hover,
.navbar-dropdown button:hover {
    color: #007bff;
}

.navbar-dropdown button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.navbar-dropdown a:last-child,
.navbar-dropdown button:last-child {
    margin-bottom: 0;
}

.navbar-dropdown button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.navbar-dropdown button:hover {
    background-color: #0056b3;
}
