/*
 * Index:
 * - Step 6 Solar Panel Configurator
 * - Title
 * - Selection Section
 * - Panel Options
 * - Month Selection
 * - Constants Input
 * - Chart Containers
 * - Monthly Data Table
 * - Confirm Button
 * - Responsive Styling for Smaller Screens
 */

/* Step 6 Solar Panel Configurator */
.step6-solar-panel-configurator {
    width: 80%;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

/* Title */
.step6-config-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: bold;
    color: #333;
}

/* Selection Section */
.step5-selection-section {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

.step6-installation-type,
.step6-constants-input,
.step6-calculated-config {
    margin-bottom: 20px;
    text-align: center;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background: #fff;
    padding: 15px;
}

/* Panel Options */
.step6-inverter-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.step6-panel-option {
    display: flex;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.step6-panel-option:hover {
    background-color: #f0f8ff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.step6-panel-option.selected {
    border-color: #007bff;
    background-color: #e6f2ff;
    font-weight: bold;
}

/* Month Selection */
.step6-month-selection {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.step6-month-checkboxes {
    display: flex;
    gap: 10px;
}

.step6-month-checkbox {
    padding: 5px;
}

/* Constants Input */
.step6-constants-input label {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
}

.step6-constants-input input {
    margin-left: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 8px;
}

/* Chart Containers */
.step6-charts-container {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 100%;
    flex-wrap: wrap;
}

.step6-chart-wrapper {
    flex: 1 1 calc(50% - 40px);
    max-width: 100%;
    margin: 10px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

/* Monthly Data Table */
.step6-monthly-data-table {
    margin: 30px auto;
    width: 100%;
    max-width: 1000px;
    border-collapse: collapse;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.step6-monthly-data-table th,
.step6-monthly-data-table td {
    padding: 12px;
    border: 1px solid #e0e0e0;
    text-align: center;
}

.step6-monthly-data-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

/* Confirm Button */
.step6-confirm-button {
    display: block;
    margin: 20px auto;
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.step6-confirm-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 768px) {
    .step6-chart-wrapper {
        flex: 1 1 100%;
    }

    .step6-charts-container {
        flex-direction: column;
    }
}
