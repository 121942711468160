/*
 * Index:
 * - Container
 * - Fieldset
 * - Legend
 * - Form Group
 * - Inputs and Selects
 * - Button
 */

/* Container */
.user-form {
    max-width: 600px;
    margin: 1rem auto;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Fieldset */
.user-form fieldset {
    border: none;
    padding: 0;
}

/* Legend */
.user-form legend {
    font-size: 18px;
    margin-bottom: 1rem;
    color: #333;
}

/* Form Group */
.user-form .form-group {
    margin-bottom: 1rem;
}

.user-form .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

/* Inputs and Selects */
.user-form .form-group input,
.user-form .form-group select {
    width: 100%;
    padding: 0.5rem;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.user-form .form-group input[type="checkbox"] {
    width: auto;
    margin-right: 0.5rem;
}

/* Button */
.user-form button {
    padding: 0.75rem 1.25rem;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.2s;
}

.user-form button:hover {
    background-color: #0056b3;
}
