/*
 * Index:
 * - User Settings Container
 * - Header
 * - Form Group
 * - Labels
 * - Inputs
 * - Buttons
 */

/* User Settings Container */
.user-settings {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header */
.user-settings h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

/* Form Group */
.user-settings .form-group {
    margin-bottom: 15px;
}

/* Labels */
.user-settings label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

/* Inputs */
.user-settings input[type="text"],
.user-settings input[type="email"],
.user-settings input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.user-settings input[type="checkbox"] {
    margin-right: 10px;
}

/* Buttons */
.user-settings button {
    display: inline-block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.user-settings button:hover {
    background-color: #0056b3;
}
