/*
 * Index:
 * - Container
 * - Div
 * - Headings
 * - Labels
 * - Inputs
 * - Actions
 * - Buttons
 * - Paragraph
 * - Responsive Design
 */

/* Container */
.project-form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    max-height: 80vh;
    overflow-y: auto;
}

/* Div */
.project-form div {
    margin-bottom: 1.5rem;
}

/* Headings */
.project-form h3, .project-form h4 {
    margin: 1rem 0;
    font-size: 20px;
    color: #333;
}

.project-form h4 {
    font-size: 18px;
    color: #555;
}

/* Labels */
.project-form label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #333;
}

/* Inputs */
.project-form input[type="text"],
.project-form input[type="number"],
.project-form input[type="checkbox"] {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
}

.project-form input[type="checkbox"] {
    width: auto;
    margin-right: 0.5rem;
    margin-bottom: 0;
}

/* Actions */
.project-form .form-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}

/* Buttons */
.project-form button {
    padding: 0.75rem 1.5rem;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
    width: 100%;
}

.project-form .btn-submit {
    background-color: #007bff;
    color: #fff;
}

.project-form .btn-submit:hover {
    background-color: #0056b3;
}

.project-form .btn-cancel {
    background-color: #6c757d;
    color: #fff;
}

.project-form .btn-cancel:hover {
    background-color: #5a6268;
}

/* Paragraph */
.project-form p {
    margin: 0.5rem 0;
    color: #777;
}

/* Responsive Design */
@media (max-width: 768px) {
    .project-form-container {
        padding: 1.5rem;
    }
}
