/*
 * Index:
 * - Step 7 Controller Configurator
 * - Title
 * - Selection Section
 * - Controller Selection
 * - Controller Options
 * - Calculated Configuration
 * - Validation Messages
 * - Additional Configuration Fields
 * - Responsive Styling for Smaller Screens
 */

/* Step 7 Controller Configurator */
.step7-controller-configurator {
    width: 80%;
    margin: 0 auto;
    line-height: 1.2;
}

/* Title */
.step7-config-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #444;
}

/* Selection Section */
.step7-regulator-type-selection {
    margin-bottom: 15px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #f4f4f4;
}

.step7-regulator-type-selection label {
    margin-right: 10px;
    cursor: pointer;
}

/* Controller Selection */
.step7-controller-selection {
    margin-bottom: 15px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #f9f9f9;
}

/* Controller Options */
.step7-controller-options {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Space between the options */
}

/* Controller Option */
.step7-controller-option {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.step7-controller-option:hover {
    background-color: #f0f8ff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.step7-controller-option.selected {
    border-color: #007bff;
    background-color: #e6f2ff;
    font-weight: bold;
}

/* Calculated Configuration */
.step7-calculated-config {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #fafafa;
}

.step7-calculated-config p {
    margin: 5px 0;
}

/* Validation Message */
.step7-valid-message {
    font-weight: bold;
    color: green;
}

.step7-invalid-message {
    font-weight: bold;
    color: red;
}

/* Additional Configuration Fields */
.step7-configuration-field {
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
}

.step7-configuration-field:last-child {
    border-bottom: none;
}

.step7-configuration-field label {
    font-weight: bold;
    font-size: 13px;
}

.step7-configuration-field span {
    font-size: 13px;
    text-align: right;
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 768px) {
    .step7-controller-configurator {
        width: 90%;
    }

    .step7-controller-options {
        gap: 8px;
    }

    .step7-controller-option {
        flex-direction: column;
        align-items: flex-start;
    }
}
