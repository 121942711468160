/*
 * Index:
 * - Admin Panel Container
 * - Header
 * - Add Button
 * - Table
 * - Table Buttons
 * - Edit Button
 * - Delete Button
 * - Error Modal
 */

/* Admin Panel Container */
.project-admin-panel-container {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

/* Header */
.project-admin-panel-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 24px;
    color: #333;
}

/* Add Button */
.project-add-button {
    display: block;
    width: 200px;
    margin: 1rem auto;
    padding: 0.5rem;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.2s;
}

.project-add-button:hover {
    background-color: #0056b3;
}

/* Table */
.project-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
}

.project-table th,
.project-table td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.project-table th {
    background-color: #f4f4f4;
}

.project-table tr:hover {
    background-color: #f1f1f1;
}

/* Table Buttons */
.project-table-button {
    padding: 0.5rem 0.75rem;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
    width: 80px;
    text-align: center;
}

.project-table-button:hover {
    transform: scale(1.05);
}

/* Edit Button */
.project-table-button-edit {
    background-color: #28a745;
    color: #fff;
}

.project-table-button-edit:hover {
    background-color: #218838;
}

/* Delete Button */
.project-table-button-delete {
    background-color: #dc3545;
    color: #fff;
}

.project-table-button-delete:hover {
    background-color: #c82333;
}

/* Error Modal */
.project-admin-panel-error-modal {
    background-color: #f8d7da;
    color: #721c24;
}
