/*
 * Index:
 * - Project List Container
 * - Header
 * - Projects Container
 * - Project Item
 * - Project Name
 * - Buttons Container
 * - Button Styles
 * - Button Hover Effects
 * - Create Button
 * - Edit Button
 * - Delete Button
 * - Create New Project Button
 */

/* Project List Container */
.project-list {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

/* Header */
.project-list h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

/* Projects Container */
.project-list .projects-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

/* Project Item */
.project-list .project-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f7f7f7;
    transition: box-shadow 0.3s, background-color 0.3s;
}

.project-list .project-item:hover {
    background-color: #f1f1f1;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Project Name */
.project-list .project-name {
    flex: 1;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    margin-left: 10px;
}

/* Buttons Container */
.project-list .project-actions {
    display: flex;
    gap: 0.75rem;
}

/* Button Styles */
.project-list .project-item button {
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s ease-in-out;
    min-width: 100px;
    text-align: center;
}

/* Button Hover Effects */
.project-list .project-item button:hover {
    transform: translateY(-2px);
}

/* Create Button */
.project-list button.create {
    background-color: #007bff;
    color: #fff;
}

.project-list button.create:hover {
    background-color: #0056b3;
}

/* Edit Button */
.project-list button.edit {
    background-color: #28a745;
    color: #fff;
}

.project-list button.edit:hover {
    background-color: #218838;
}

/* Delete Button */
.project-list button.delete {
    background-color: #dc3545;
    color: #fff;
}

.project-list button.delete:hover {
    background-color: #c82333;
}

/* Create New Project Button */
.project-list .create {
    display: block;
    width: 100%;
    padding: 0.75rem;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    text-align: center;
    border: none;
    border-radius: 6px;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s ease-in-out;
}

.project-list .create:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}
