/*
 * Index:
 * - Wrapper
 * - Chart Section
 * - Form Section
 * - Appliance List Section
 * - Appliance Table
 * - Buttons
 * - Media Queries
 */

/* Container */
.step2-container {
    max-width: 1300px;
    margin: 0 auto;
}

/* Appliance Page Container */
.step2-appliance-page-container {
    display: flex;
    flex-direction: row;
    padding: 20px;
    box-sizing: border-box;
    align-items: flex-start;
    margin-bottom: 20px;
}

/* Chart Section */
.step2-chart-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    text-align: center;
    padding: 0 20px;
    flex-wrap: wrap;
    align-items: flex-start;
}

/* Chart Container */
.step2-chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 300px;
    min-width: 250px;
    max-width: 400px;
    margin-bottom: 20px;
}

/* Center chart title above each chart */
.chart-title {
    margin-bottom: 10px;
    font-weight: bold;
}

/* Form Section */
.step2-form-section {
    flex: 1;
    margin-right: 20px;
}

/* Appliance Form */
.step2-appliance-form {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
}

/* Input Group */
.step2-input-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.step2-input-group label {
    display: block;
    margin-right: 10px;
    font-weight: bold;
}

.step2-input-group input,
.step2-input-group select {
    flex: 1;
    padding: 8px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Action Button */
.step2-action-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 16px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    text-align: center;
}

/* Appliance List Section */
.step2-appliance-list-section {
    flex: 1;
}

/* Appliance Table */
.step2-appliance-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.step2-appliance-table th,
.step2-appliance-table td {
    padding: 8px;
    text-align: center;
    border-bottom: 1px solid #dcdcdc;
}

.step2-appliance-table th {
    background-color: #f8f9fa;
    font-weight: bold;
}

.step2-appliance-table tr:hover {
    background-color: #f1f1f1;
}

/* Button Group */
.step2-button-group {
    display: flex;
    justify-content: center;
    gap: 5px;
}

/* Edit Button */
.step2-edit-button {
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    padding: 6px 10px;
    font-size: 14px;
    cursor: pointer;
    color: white;
}

.step2-edit-button:hover {
    background-color: #218838;
}

/* Delete Button */
.step2-delete-button {
    background-color: #dc3545;
    border: none;
    border-radius: 4px;
    padding: 6px 10px;
    font-size: 14px;
    cursor: pointer;
    color: white;
}

.step2-delete-button:hover {
    background-color: #c82333;
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 1024px) {
    .step2-appliance-page-container {
        flex-direction: column;
    }

    .step2-form-section {
        margin-right: 0;
    }

    /* Stack charts vertically on medium screens */
    .step2-chart-section {
        flex-direction: column;
        align-items: center;
    }

    .step2-chart-container {
        width: 100%;
        max-width: 300px;
        margin-bottom: 20px;
    }
}

/* Further adjust charts on even smaller screens */
@media (max-width: 768px) {
    .step2-chart-section {
        flex-direction: column;
        align-items: center;
    }

    .step2-chart-container {
        width: 100%;
        max-width: 250px;
        margin-bottom: 20px;
    }
}
