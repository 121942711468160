/*
 * Index:
 * - User List Container
 * - Header
 * - Controls
 * - Add Button
 * - Search
 * - Table
 * - Table Buttons
 * - Error Modal
 */

/* User List Container */
.user-list-container {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

/* Header */
.user-list-header {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 24px;
    color: #333;
}

/* Controls */
.user-list-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

/* Add Button */
.user-list-add-button {
    padding: 0.5rem 1rem;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.2s;
}

.user-list-add-button:hover {
    background-color: #0056b3;
}

/* Search */
.user-list-search-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 500px;
}

.user-list-search-input {
    flex: 1;
    padding: 0.5rem;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 0.5rem;
}

.user-list-search-button {
    padding: 0.5rem;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.2s;
}

.user-list-search-button:hover {
    background-color: #0056b3;
}

/* Table */
.user-list-table-container {
    width: 100%;
}

.user-list-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    text-align: left;
}

.user-list-table th,
.user-list-table td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.user-list-table th {
    background-color: #f4f4f4;
}

.user-list-table tr:hover {
    background-color: #f1f1f1;
}

/* Table Buttons */
.user-list-button {
    padding: 0.5rem 0.75rem;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
    width: 80px;
    text-align: center;
}

.user-list-button:hover {
    transform: scale(1.05);
}

.user-list-button-edit {
    background-color: #28a745;
    color: #fff;
}

.user-list-button-edit:hover {
    background-color: #218838;
}

.user-list-button-delete {
    background-color: #dc3545;
    color: #fff;
}

.user-list-button-delete:hover {
    background-color: #c82333;
}

/* Error Modal */
.user-list-error-modal {
    background-color: #f8d7da;
    color: #721c24;
    padding: 1rem;
    border-radius: 4px;
    text-align: center;
}
