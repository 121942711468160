/*
 * Index:
 * - Modal Overlay
 * - Content
 * - Header
 * - Title
 * - Close Button
 * - Body
 * - Animation
 */

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Content */
.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    max-width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: modalFadeIn 0.3s ease-out;
    position: relative;
}

/* Header */
.modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

/* Title */
.modal-title {
    margin: 0;
    font-size: 1.5em;
    flex-grow: 1;
    text-align: center;
}

/* Close Button */
.modal-close {
    position: absolute;
    right: 0;
    background: none;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
    color: #333;
    width: auto;
    transition: color 0.2s ease;
}

.modal-close:hover {
    color: #999;
}

/* Body */
.modal-body {
    font-size: 1em;
    margin-top: 20px;
}

/* Animation */
@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
