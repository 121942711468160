/*
 * Index:
 * - Map Page Container
 * - Content
 * - Left Column (Map Section)
 * - Controls
 * - Search Block
 * - Map Wrapper
 * - Location Info
 * - Chart Section
 * - Input Fields
 * - Media Queries
 */

/* Map Page Container */
.step3-map-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

/* Content */
.step3-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 1300px;
    margin: 20px auto;
    gap: 20px;
    flex-wrap: wrap;
}

/* Left Column (Map Section) */
.step3-left-column {
    flex: 3;
    display: flex;
    flex-direction: column;
    min-width: 600px;
}

/* Controls */
.step3-controls {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 250px;
    min-height: 300px;
    box-sizing: border-box;
    align-self: flex-start;
}

/* Search Block */
.step3-search-block {
    display: flex;
    margin-bottom: 10px;
}

/* Search Bar */
.step3-search-bar {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 1;
}

#locationSearch {
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    padding: 10px;
    flex: 4;
}

/* Search Button */
.step3-button {
    justify-content: center;
    display: flex;
    flex: 1;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 16px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.step3-search-button:hover {
    background-color: #0056b3;
}

/* Map Wrapper */
.step3-map-wrapper {
    flex: 1;
    min-width: 0;
}

/* Form Group */
.step3-form-group {
    margin-bottom: 10px;
}

/* Location Info */
.step3-location-info {
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    box-sizing: border-box;
}

/* Flex Container for Charts */
.step3-chart-flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1300px;
    margin-top: 20px;
    gap: 20px;
}

/* Chart Container */
.step3-chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48%;
    margin: 10px 0;
    max-width: 600px;
}

/* Bar Chart Title */
.step3-chart-container h3 {
    text-align: center;
    margin-bottom: 10px;
}

/* Input Fields */
.step3-form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 1280px) {
    .step3-content {
        flex-direction: column;
        align-items: center;
    }

    .step3-left-column {
        width: 100%;
        min-width: 0;
    }

    .step3-controls {
        width: 100%;
        max-width: 400px;
        align-self: center;
        margin-top: 20px;
    }
}

@media (max-width: 1280px) {
    .step3-chart-container {
        width: 100%;
        max-width: none;
    }
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 1024px) {
    .step3-content {
        flex-direction: column;
        align-items: center;
    }

    .step3-left-column {
        width: 100%;
        min-width: 0;
    }

    .step3-controls {
        width: 100%;
        max-width: 400px;
        align-self: center;
        margin-top: 20px;
    }
}
