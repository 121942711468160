/*
 * Index:
 * - Home Container Styling
 * - Home Heading Styling
 * - Home Content Styling
 * - Projects Button Styling
 * - Program Info Section Styling
 * - Manual Download Link Styling
 */

/* Home Container Styling */
.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

/* Home Heading Styling */
.home-heading {
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
}

/* Home Content Styling */
.home-content {
    text-align: center;
    max-width: 600px;
    width: 100%;
}

/* Projects Button Styling */
.home-projects-button {
    display: inline-block;
    padding: 15px 30px;
    margin: 20px 0;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 8px;
    font-size: 24px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.home-projects-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

/* Program Info Section Styling */
.home-program-info {
    margin-top: 50px;
    padding: 20px;
    border: 2px solid #007bff;
    border-radius: 8px;
    background-color: #f8f9fa;
    text-align: center;
}

.home-program-info h2 {
    font-size: 26px;
    margin-bottom: 10px;
    color: #000000;
}

.home-program-info p {
    font-size: 18px;
    margin: 10px 0;
    color: #333;
}

/* Manual Download Link Styling */
.home-manual-download-link {
    display: inline-block;
    margin-top: 10px;
    font-size: 18px;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.home-manual-download-link:hover {
    text-decoration: underline;
}
