/*
 * Index:
 * - Body Styling
 */

/* Body Styling */
body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Helvetica, sans-serif;
}
