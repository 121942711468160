/*
 * Index:
 * - Register Container Styling
 * - Header Styling
 * - Form Styling
 * - Input Styling
 * - Button Styling
 * - Error Message Styling
 */

/* Register Container Styling */
.register-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Header Styling */
.register-container h2 {
    margin-bottom: 20px;
    text-align: center;
}

/* Form Styling */
.register-container form {
    display: flex;
    flex-direction: column;
}

/* Input Styling */
.register-container input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Button Styling */
.register-container button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.register-container button:hover {
    background-color: #0056b3;
}

/* Error Message Styling */
.register-container p {
    text-align: center;
    color: #d9534f;
}

/* Additional Button Styling */
.register-container button:last-of-type {
    margin-top: 10px;
}
