/*
 * Index:
 * - Wizard Container Styling
 * - Wizard Steps Styling
 * - Wizard Content Styling
 * - Wizard Buttons Styling
 * - Disabled Button Styling
 */

/* Wizard Container Styling */
.wizard-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 80vh;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    overflow: hidden;
}

/* Wizard Steps Styling */
.wizard-steps {
    margin-bottom: 15px;
}

.wizard-steps-container {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    overflow: hidden;
}

.wizard-step {
    flex: 1;
    text-align: center;
    padding: 8px;
    background: #f9f9f9;
    font-size: 0.875rem;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    border: 2px solid transparent;
}

.wizard-step:last-child {
    border-right: none;
}

.wizard-step.active {
    background: #0056b3;
    color: white;
    border-color: #0056b3;
    box-shadow: 0 0 8px rgba(0, 91, 187, 0.5);
}

.wizard-step.done {
    background: #007bff;
    color: white;
    border-color: #007bff;
}

.wizard-step.enabled {
    background: #68b2ff;
    color: white;
    border-color: #77bdff;
}

.wizard-step.disabled {
    background: #e0e0e0;
    color: #a0a0a0;
    cursor: not-allowed;
    pointer-events: none;
}

/* Wizard Content Styling */
.wizard-content {
    flex: 1;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f0f2f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    overflow: visible;
}

/* Wizard Buttons Styling */
.wizard-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: auto;
    padding-top: 10px;
    background-color: #f1f1f1;
    border-top: 1px solid #e0e0e0;
}

.wizard-buttons button {
    padding: 8px 16px;
    font-size: 0.875rem;
    border: none;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    flex: 1;
    min-width: 0;
}

.wizard-buttons button.next {
    background-color: #007bff;
}

.wizard-buttons button.previous {
    background-color: #6c757d;
}

/* Disabled Button Styling */
.wizard-buttons button:disabled {
    background: #d3d3d3;
    color: #888;
    cursor: not-allowed;
    opacity: 0.6;
    border: 1px solid #b0b0b0;
    box-shadow: none;
    pointer-events: none;
}
