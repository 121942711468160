/*
 * Index:
 * - Shared Layout Container Styling
 * - Main Content Styling
 * - Footer Styling
 */

/* Shared Layout Container Styling */
.shared-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f0f2f5;
}

/* Main Content Styling */
main {
    flex: 1;
    padding: 20px;
}

/* Footer Styling */
footer {
    margin-top: auto;
    background-color: #ffffff;
    border-top: 1px solid #ddd;
    padding-top: 5px;
    text-align: center;
}
