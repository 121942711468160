/*
 * Index:
 * - Main Container
 * - Headers
 * - Section Container
 * - Smaller Titles for Charts
 * - Chart Row for Side-by-Side Layout
 * - List Styling
 * - Table Styling
 * - Map Container
 * - Constants Table
 * - Buttons
 */

/* Main Container */
.final-step-container {
    padding: 20px;
    background-color: #f8f8f8;
    color: #333;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

/* Headers */
.final-step-container h2 {
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
}

.final-step-container h3 {
    color: #333;
    font-size: 22px;
    margin-bottom: 15px;
    text-align: left;
}

/* Section Container */
.final-step-section {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Smaller Titles for Charts */
.chart-item h3 {
    font-size: 18px;
    color: #555;
    margin-bottom: 10px;
}

/* Chart Row for Side-by-Side Layout */
.final-step-chart-row {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 100%;
}

.final-step-chart-item {
    flex: 1;
    text-align: center;
}

/* List Styling */
.final-step-list {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
    font-size: 14px;
    color: #333;
}

.final-step-list li {
    padding: 5px 0;
}

/* Table Styling */
.final-step-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
    font-size: 14px;
}

.final-step-table th,
.final-step-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
}

.final-step-table th {
    background-color: #f0f0f0;
    font-weight: bold;
    color: #333;
}

/* Map Container */
.final-step-map-container {
    margin-top: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
}

/* Constants Table */
.final-step-constants-section {
    margin-top: 15px;
}

.final-step-constants-section h4 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

.final-step-constants-section table {
    width: 50%;
    margin: 0 auto;
    border-collapse: collapse;
    color: #333;
}

.final-step-constants-section th,
.final-step-constants-section td {
    padding: 8px;
    border: 1px solid #ddd;
}

.final-step-constants-section th {
    background-color: #f0f0f0;
    font-weight: bold;
    color: #333;
}

/* Buttons */
.final-step-button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

@media print {
    .export-button {
        display: none;
    }
}
