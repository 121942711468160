/*
 * Index:
 * - Map Container
 * - Map
 */

/* Map Container */
.map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Map */
.map {
    width: 100%;
    height: 455px;
    border: 1px solid #ddd;
    border-radius: 8px;
}
