/*
 * Index:
 * - Step 4 Inverter Page Container
 * - Step 7 Config Title
 * - Selection Section
 * - Inverter List Section
 * - Inverter Options
 * - Inverter Option
 * - Energy Calculations
 * - Media Queries for Responsiveness
 */

/* Step 4 Inverter Page Container */
.step4-inverter-page-container {
    width: 80%;
    margin: 0 auto;
    line-height: 1.2;
}

/* Step 7 Config Title */
.step7-config-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #444;
}

/* Selection Section */
.step4-selection-section {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

.step4-input-group {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #f4f4f4;
}

.step4-input-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
}

.step4-input-group select {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
}

/* Inverter List Section */
.step4-inverter-list-section {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #fafafa;
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 20px;
}

/* Inverter Options */
.step4-inverter-options {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* Inverter Option */
.step4-inverter-option {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.step4-inverter-option:hover {
    background-color: #f0f8ff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.step4-inverter-option.selected {
    border-color: #007bff;
    background-color: #e6f2ff;
    font-weight: bold;
}

/* Energy Calculations */
.step4-energy-calculations {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #f9f9f9;
}

.step4-energy-calculations p {
    margin: 5px 0;
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 768px) {
    .step4-page-container {
        width: 90%;
    }

    .step4-selection-section {
        flex-direction: column;
        gap: 15px;
    }

    .step4-inverter-option {
        flex-direction: column;
        align-items: flex-start;
    }
}
